
/**
 * First we will load all of this project's JavaScript dependencies which
 * include Vue and Vue Resource. This gives a great starting point for
 * building robust, powerful web applications using Vue and Laravel.
 */

//require('./bootstrap');

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

$(document).ready(function() 
{
  console.log('ready');
  
  $('.block').click(function(e) {
    $(this).find('.full-content').slideToggle();
  });
  
  resizeStuff();
});

$(window).resize(function() 
{
  resizeStuff();
  
});

function resizeStuff() 
{
  $('iframe').height($('#video-size-base').height());
}

function openInterestPopin(photo_id)
{
 console.log('!', photo_id); 
  $.get('/photos/interest/add/'+photo_id, function(data){
    $('#interest-popin .modal-body').html(data);   
    $('#interest-popin').modal('show');
    
  });
}

function removeOneInterest(element, photo_id)
{
  $(element).parent().parent().slideUp();
  $.get('/photos/interest/remove/'+photo_id, function(data){
    //nothing to do
  });
}

function openPopup(link)
{$.get(link,{popup:true},function(data)
{$('#popup-bg .text').html(data);});$('#popup-bg').fadeIn(300);}
function closePopup()
{$('#popup-bg').fadeOut(300,function(){$('#popup-bg .text').html('<img src="/img/layout/loading.gif" />');})}
function newInterest()
{openPopup('/photo/interest?photo_id='+$('#photo-id').val());}
function removeInterest(e,photo_id)
{$(e).parent().parent().remove();$.post('/photo/removeInterest',{'photo_id':photo_id},function(data)
{data=jQuery.parseJSON(data);});}

String.prototype.allReplace = function(obj) {
    var retStr = this;
    for (var x in obj) {
        retStr = retStr.replace(new RegExp(x, 'g'), obj[x]);
    }
    return retStr;
};

